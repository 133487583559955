import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-tac-dialog',
	templateUrl: './tac-dialog.component.html',
	styleUrls: ['./tac-dialog.component.scss']
})
export class TacDialogComponent {
	termsAndConditions: string;

	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.termsAndConditions = this.dynamicDialogConfig.data.termsAndConditions;
	}

	accept() {
		this.dynamicDialogRef.close({
			accepted: true
		});
	}

	cancel() {
		this.dynamicDialogRef.close({
			accepted: false
		});
	}
}
